















































































































































































































































































































































































































































































































































































































































































































.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
/deep/ .el-input-group__append,
/deep/.el-input-group__prepend {
  background-color: transparent;
  /* color: #909399; */
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: none;
  border-radius: 4px;
  padding: 0 20px;
  width: 1px;
  white-space: nowrap;
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 30px;
  }
}
.iconStyle {
  font-size: 2rem;
  color: #6676ff;
  cursor: pointer;
}
